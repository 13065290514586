import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";

export default function Tools() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">0xTools Platform</Heading>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={8} xl={8}>
                                <div>
                                    Take your crypto project to the next level! The 0xTools platform gives you the tools to make your project stronger and more reliable.
                                </div>
                                <div>
                                    It helps you build trust with your users and stand out in the blockchain world. With 0xTools, you can create a project that’s powerful, trusted, and ready to succeed!
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h1>Standard Version</h1>
                                </div>
                                <br/>
                                <h4>Details : </h4>
                                <div>- Your Users can swap between any 2 tokens</div>
                                <div>- Your token will be the default swap token with BNB</div>
                                <div>- Your Users can create tokens</div>
                                <div>- Your Users can airdrop websites</div>
                                <div>- Your Users can launchpad websites</div>
                                <div>- Video tutorials</div>
                                <h5> Demo : <a href="https://bep20.net/" target="_blank" rel="noreferrer">Click here to see live demo</a></h5>
                                <br/>
                                <h4>Price : $100 ONLY</h4>
                                <br/>
                                <div className="text-center">
                                <a href="https://t.me/mohamedmox" target="_blank" rel="noreferrer"><h3 style={{color: 'yellow'}}>Contact Us</h3></a>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <div className="text-center">
                                    <h1>Premium Version (Earn Fee)</h1>
                                </div>
                                <br/>
                                <div>- Earn Swap Fee ($1000)</div>
                                <div>- Earn Tokens Creation Fee ($500)</div>
                                <div>- Earn Airdrop Creation Fee ($1000)</div>
                                <div>- Earn Launchpad Creation Fee ($1000)</div>
                                <h5> Demo : <a href="https://bep20.net/" target="_blank" rel="noreferrer">Click here to see live demo</a></h5>
                                <br/>
                                <h4>Take it All : $3000 (and save $500)</h4>
                                <br/>
                                <b/>
                                <div className="text-center">
                                <a href="https://t.me/mohamedmox" target="_blank" rel="noreferrer"><h3 style={{color: 'yellow'}}>Contact Us</h3></a>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} xl={4}>
                                <Box>
                                    <div className="text-center">
                                        <img src="images/tools.png" width={"100%"} alt="Dex" />
                                    </div>
                                    <br/>
                                </Box>
                            </Col>
                    </Row>
                </Container>
        </PageLayout>
    )
}
